import React from 'react';
import './services.css';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import {createTheme, ThemeProvider} from '@mui/material/styles';
import MyFooter from '../../components/Footer/myfooter';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import {CardActionArea} from '@mui/material';

import {ReactComponent as Cruise} from '../../images/cruise.svg';
import {ReactComponent as Gears} from '../../images/engine-gears.svg';
import {ReactComponent as Repair} from '../../images/repair-service.svg';


const cards = [{
    content: 'Engine Repair.', aq: require("../../images/engine-repair.jpg")
}, {
    content: 'Generator Repair.', aq: require("../../images/Generator.jpeg")
}, {
    content: 'Cooler Fabrication & Repair.', aq: require("../../images/cooler-fabrication.jpeg")
}, {
    content: 'Crank Shaft Grinding In SITU.', aq: require("../../images/crank-shaft.jpg")
}, {
    content: 'Cylinder Head Repair, Grinding, Facing, etc..', aq: require("../../images/Cylinder-Head-repair.jpeg")
}, {
    content: 'Fabrication of Hydraulic Jacket.', aq: require("../../images/Fabrication-Hydraulic-Jacke.jpeg")
}, {
    content: 'Line Boring Machine for Main Bearing Repair.', aq: require("../../images/line-boaring.jpg")
}, {
    content: 'Fabrication of Water Pump Impeller.', aq: require("../../images/water-pump.jpeg")
}, {
    content: 'Remetalling of Bearing using White Metal.', aq: require("../../images/Rematl.jpeg")
}, {
    content: 'Repair ship compressors.', aq: require("../../images/air-compressors-on-ships.jpg")
}];

const theme = createTheme();

const Services = () => {
    return (<div>

        <box sx={{flexGrow: 1}} className="services-grid1">
            <Grid container spacing={{xs: 1, md: 7, xl: 7}}>
                <Grid item xs={12} sm={12} md={6}>
                    <h1 className="services-h2">Intrados Marine Services</h1>
                    <hr style={{
                        width: '50%',
                        paddingBottom: '1px',
                        color: 'gray',
                        backgroundColor: '#3CAFE1',
                        marginLeft: '0',
                        marginTop: '8px'
                    }}/>
                    <div>
                        <p className="services-p">
                            Intrados has been providing world class vessel and ship repair services since 2004.
                            With the headquarters located in the United Arab Emirates. We have highly trained
                            technicians,
                            engineers and Fabricators to meet our customer's needs, on-site using our mobile in-house
                            equipment and resources.
                        </p>
                        <p className="services-p"> We can provide our services to any port
                            in the United Arab Emirates. Our quick services and availability of ready stock assure a
                            fast, reliable,
                            and dependable supply at any moment. Our Services are available 24 hours, 7 days a
                            week for all our customers, whether the ships are at berth or
                            outside the port limits. </p>
                    </div>
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                    <img className="main-image" src={require('../../images/services-ship 1.jpg')} alt="ship"
                         style={{height: '90%', width: '90%'}}/>
                </Grid>
            </Grid>
        </box>


        <box sx={{flexGrow: 1}} className="services-grid2">
            <Grid container spacing={{xs: 1, md: 3, xl: 0}}>
                <Grid item xs={12} sm={12} md={12}>
                    <h1 className="services-h2" style={{textAlign: 'center'}}>Our Services</h1>
                    <hr style={{width: '24%', paddingBottom: '1px', color: 'gray', backgroundColor: '#3CAFE1'}}/>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <div style={{textAlign: 'center', paddingBottom: '10%'}}><Cruise
                        style={{height: '30%', width: '30%'}}/></div>
                    <h3 className="services-textH">Ship Repair Services</h3>
                    <p className="services-textP">Ship Maintenance And Repair</p>
                </Grid> <Grid item xs={12} sm={6} md={4}>
                <div style={{textAlign: 'center', paddingBottom: '7%'}}><Gears style={{height: '30%', width: '30%'}}/>
                </div>
                <h3 className="services-textH">Supply Spare Parts</h3>
                <p className="services-textP">Licensed Suppliers</p>
            </Grid> <Grid item xs={12} sm={6} md={4}>
                <div style={{textAlign: 'center', paddingBottom: '18%'}}><Repair style={{height: '30%', width: '30%'}}/>
                </div>
                <h3 className="services-textH">Other Services</h3>
                <p className="services-textP">Tailor Services For Your Needs</p>
            </Grid>
            </Grid>
        </box>

        <box sx={{flexGrow: 1}} className="services-grid3">
            <Grid container spacing={{xs: 1, md: 5, xl: 4}}>
                <Grid item xs={12} sm={12} md={6}>
                    <h2 className="services-h2">Our Ship Repair Services</h2>
                    <hr style={{
                        width: '50%',
                        paddingBottom: '1px',
                        color: 'gray',
                        backgroundColor: '#3CAFE1',
                        marginLeft: '0',
                        marginTop: '8px'
                    }}/>
                    <div className="list-container">
                        <ul className="list-service">
                            <li><CheckCircleIcon style={{paddingRight: '1%', fill: '#00DB6F'}}/>Repair and Overhaul
                                All types of Engines
                            </li>

                            <li><CheckCircleIcon style={{paddingRight: '1%', fill: '#00DB6F'}}/>Repair all types of
                                Generators
                            </li>
                            <li><CheckCircleIcon style={{paddingRight: '1%', fill: '#00DB6F'}}/>Fabrication of Hydraulic
                                Jacket
                            </li>
                            <li><CheckCircleIcon style={{paddingRight: '1%', fill: '#00DB6F'}}/>Cooler Fabrication and
                                Repair
                            </li>
                            <li><CheckCircleIcon style={{paddingRight: '1%', fill: '#00DB6F'}}/>Engine Cylinder Head
                                Repair-Pressure & Cracks Test
                            </li>
                            <li><CheckCircleIcon style={{paddingRight: '1%', fill: '#00DB6F'}}/>Crank Shaft Grinding on
                                board (In SITU) for all types of Engines
                            </li>
                            <li><CheckCircleIcon style={{paddingRight: '1%', fill: '#00DB6F'}}/>Fabrication of Water
                                Pump Impeller
                            </li>
                            <li><CheckCircleIcon style={{paddingRight: '1%', fill: '#00DB6F'}}/>General Turning Services
                            </li>
                            <li><CheckCircleIcon style={{paddingRight: '1%', fill: '#00DB6F'}}/>Remetalling of Bearing
                                using White Metal
                            </li>
                            <li><CheckCircleIcon style={{paddingRight: '1%', fill: '#00DB6F'}}/>Compressor Overhauling
                            </li>
                            <li><CheckCircleIcon style={{paddingRight: '1%', fill: '#00DB6F'}}/>Cylinder Head Repairs,
                                Grinding, Facing & Lapping
                            </li>
                            <li><CheckCircleIcon style={{paddingRight: '1%', fill: '#00DB6F'}}/>Repairs all kinds of
                                Fresh Water and Sea Water Pumps
                            </li>
                            <li><CheckCircleIcon style={{paddingRight: '1%', fill: '#00DB6F'}}/>All Compressor for Ships
                            </li>
                            <li><CheckCircleIcon style={{paddingRight: '1%', fill: '#00DB6F'}}/>Line Boring Machine for
                                Main Bearing Repairs
                            </li>
                        </ul>
                    </div>
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                    <img className="main-image" src={require('../../images/Services-ship.jpg')} alt="ship"
                         style={{height: '90%', width: '90%'}}/>
                </Grid>
            </Grid>
        </box>

        <div className="hr-line"></div>

        <ThemeProvider theme={theme}>
            <CssBaseline/>
            <main>
                <Box>
                    <Container maxWidth="sm">
                        <Typography
                            component="h1"
                            variant="h2"
                            align="center"
                            color="text.primary"
                            gutterBottom
                        >
                            Our Work
                        </Typography>
                        <hr style={{
                            width: '50%',
                            paddingBottom: '1px',
                            color: 'gray',
                            backgroundColor: '#3CAFE1',
                            marginLeft: '25%',
                            marginRight: '25%',
                            marginTop: '8px',
                            align: 'center'
                        }}/>
                        <Typography variant="h5" align="center" color="text.secondary" paragraph>
                            Following gallery shows some of our services, and on site work.
                        </Typography>
                    </Container>
                </Box>
                <Container sx={{py: 0.2}} maxWidth="lg" style={{width: '85%'}}>
                    {/* End hero unit */}
                    <Grid container spacing={4}>
                        {cards.map((card) => (<Grid item key={card} xs={12} sm={6} md={4}>
                            <CardActionArea>
                                <Card
                                    sx={{height: '100%', display: 'flex', flexDirection: 'column'}}
                                >
                                    <CardMedia
                                        component="img"
                                        sx={{
                                            // 16:9
                                            // pt: '100%',
                                        }}
                                        image={card.aq}
                                        alt="services"
                                        style={{width: '400px', height: '400px'}}
                                    />
                                    <CardContent sx={{flexGrow: 1}} style={{maxHeight: '70px', marginTop: '10px'}}>
                                        <Typography gutterBottom variant="h7" component="h1" align="center"
                                                    style={{
                                                        color: '#00334E',
                                                        fontFamily: "Helvetica",
                                                        fontSize: '17px'
                                                    }}>
                                            {card.content}
                                        </Typography>
                                    </CardContent>
                                </Card>
                            </CardActionArea>
                        </Grid>))}
                    </Grid>
                </Container>
            </main>
        </ThemeProvider>

        <MyFooter></MyFooter>


    </div>);
};

export default Services;
