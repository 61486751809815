import React from 'react'
import Grid from "@mui/material/Grid";
import './about.css';

import MyFooter from '../../components/Footer/myfooter';

// import Card from '@mui/material/Card';
// import CardContent from '@mui/material/CardContent';
// import CardMedia from '@mui/material/CardMedia';
// import Typography from '@mui/material/Typography';
// import {CardActionArea} from '@mui/material';
// import Container from "@mui/material/Container";


// const styles = theme => ({
//     Card: {
//         width: 300, margin: 'auto'
//     }, Media: {
//         height: 550, width: '100%', objectFit: 'cover'
//     }
// });


const About = () => {


    return (<div>
        <box sx={{flexGrow: 1}} className="about-grid1">
            <Grid container spacing={{xs: 1, md: 3, xl: 0}}>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <h2 className="about-h1">About Us</h2>
                    <hr style={{width: '24%', paddingBottom: '1px', color: 'gray', backgroundColor: '#3CAFE1'}}/>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <div  className="width-p">
                        <p className="about-p">
                            Intrados was founded by entrepreneurs Rabie Sohail Akrah and
                            Mahmoud Zeyad Salah in 2004, located in the United Arab Emirates, to
                            provide ships repair & maintenance services
                            for a wide and diverse clientele base.
                        </p>
                        <p className="about-p">
                            In the United Arab Emirates, we strive to be the most customer-focused and cost
                            effective solution provider organization in the region for ship owners and
                            managers.
                        </p>
                        <p className="about-p">
                            We at Intrados not only believe in meeting our customers' needs, but we also
                            believe in always improving and improving our services and talents in order to be
                            on top! In dealing with us, you will be dealing with a highly specialized and
                            experienced team of professionals, whose executives have extensive sailing and
                            shore-based expertise.
                        </p>
                    </div>
                </Grid>

                {/*<hr style={{width: '100%', paddingBottom: '20px'}}/>*/}

                {/*<Grid item xs={12} sm={12} md={6} lg={6} xl={6}>*/}

                {/*    <Container sx={{py: 0.2}} maxWidth="sm" style={{width: '70%', maxWidth: '380px'}}>*/}
                {/*        <Card sx={{height: '100%', display: 'flex', flexDirection: 'column'}}*/}
                {/*        >*/}
                {/*            <CardActionArea>*/}
                {/*                <CardMedia*/}
                {/*                    component="img"*/}
                {/*                    image={require("../../images/Mahmoud.png")}*/}

                {/*                    alt="Mahmoud"*/}
                {/*                    style={{width: '300px', height: '400px'}}*/}
                {/*                />*/}

                {/*                <CardContent>*/}
                {/*                    <h4 style={{textAlign: 'center'}}>*/}
                {/*                        Mahmoud Zeyad Salah*/}
                {/*                    </h4>*/}
                {/*                    <h6 style={{textAlign: 'center'}}>*/}
                {/*                        Executive Director*/}
                {/*                    </h6>*/}
                {/*                </CardContent>*/}
                {/*            </CardActionArea>*/}
                {/*        </Card>*/}
                {/*    </Container>*/}


                {/*</Grid>*/}


                {/*<Grid item xs={12} sm={12} md={6} lg={6} xl={6}>*/}

                {/*    <Container sx={{py: 0.2}} maxWidth="md" style={{width: '70%', maxWidth: '380px'}}>*/}
                {/*        <Card sx={{height: '100%', display: 'flex', flexDirection: 'column'}}*/}
                {/*        >*/}
                {/*            <CardActionArea>*/}
                {/*                <CardMedia*/}
                {/*                    component="img"*/}
                {/*                    image={require("../../images/Rabie.png")}*/}

                {/*                    alt="Rabie"*/}
                {/*                    style={{width: '300px', height: '400px'}}*/}
                {/*                    objectFit={true}*/}
                {/*                />*/}

                {/*                <CardContent>*/}
                {/*                    <h4 style={{textAlign: 'center'}}>*/}
                {/*                        Rabie Sohail Akrah*/}
                {/*                    </h4>*/}
                {/*                    <h6 style={{textAlign: 'center'}}>*/}
                {/*                        GM & Technical Manager*/}
                {/*                    </h6>*/}
                {/*                </CardContent>*/}
                {/*            </CardActionArea>*/}
                {/*        </Card>*/}
                {/*    </Container>*/}


                {/*</Grid>*/}



            </Grid>
        </box>
        <MyFooter/>
    </div>);
};

export default About;
