import React, {useState} from 'react';
import styles from '../contact/contact.css'
import emailjs from 'emailjs-com'
import Button from '@mui/material/Button';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import EmailRoundedIcon from '@mui/icons-material/EmailRounded';
import MyFooter from '../../components/Footer/myfooter';
import ReCAPTCHA from "react-google-recaptcha";
import {createTheme, ThemeProvider} from '@mui/material/styles';


const theme = createTheme({
    palette: {
        primary: {
            // Purple and green play nicely together.
            main: '#d62196',
        }
    },
});

const isValidEmail = email => {
    const regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return regex.test(String(email).toLowerCase());
};


const Contact = () => {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [emailSent, setEmailSent] = useState(false);

    const submit = () => {
        if (!isValidEmail(email)) {
            alert('Enter a valid email.');
            return;
        }

        var userId = 'EPOkHYJx2tw-LJ3NL';
        emailjs.init(userId);
        if (name && email && message) {
            const serviceId = 'service_dy17i1j';
            const templateId = 'template_sajsqri';
            const templateParams = {
                name, email, message
            }

            emailjs.send(serviceId, templateId, templateParams).then((result) => {
                alert("Message Submitted Successfully");
            });

            setName('');
            setEmail('');
            setMessage('');
            setEmailSent(true);
        } else {
            alert('Please fill in all fields.');
        }
    }

    return (<div>
        <section className="ftco-section">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-md-6 text-center mb-5">
                        <h2 className="heading-section">Send us a message</h2>
                    </div>
                </div>
                <div className="row justify-content-center">
                    <div className="col-md-10">
                        <div className="wrapper">
                            <div className="row no-gutters">
                                <div className="col-md-6">
                                    <div className="contact-wrap w-100 p-lg-5 p-4">
                                        <div id="contactForm" name="contactForm" className="contactForm">
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <div className="form-group">
                                                        <input type="text" className="form-control" name="name"
                                                               id="name" placeholder="Name" value={name}
                                                               onChange={e => setName(e.target.value)}/>
                                                    </div>
                                                </div>
                                                <div className="col-md-12">
                                                    <div className="form-group">
                                                        <input type="email" className="form-control" name="email"
                                                               id="email" placeholder="Email" value={email}
                                                               onChange={e => setEmail(e.target.value)}/>
                                                    </div>
                                                </div>
                                                <div className="col-md-12">
                                                    <div className="form-group">
                                                        <textarea name="message" className="form-control" id="message"
                                                                  cols="30" rows="6" placeholder="Message"
                                                                  value={message}
                                                                  onChange={e => setMessage(e.target.value)}></textarea>
                                                    </div>
                                                </div>
                                                <ReCAPTCHA
                                                    sitekey="6Lfbn7UfAAAAAMkgtoGESidjEELv7c8V4tHV9fpc"
                                                />
                                                {/*<div className="g-recaptcha"*/}
                                                {/*     data-sitekey="6Lfbn7UfAAAAAMkgtoGESidjEELv7c8V4tHV9fpc"></div>*/}
                                                <br/>
                                                <div className="col-md-12">
                                                    <ThemeProvider theme={theme}>
                                                        <Button variant="contained" onClick={submit}>Submit</Button>
                                                    </ThemeProvider>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6 d-flex align-items-stretch">
                                    <div className="info-wrap w-100 p-lg-5 p-4 img">
                                        <h3>Contact us</h3>
                                        <p className="mb-4"></p>
                                        <div className="dbox w-100 d-flex align-items-start">
                                            <div className="icon d-flex align-items-center justify-content-center">
                                                <LocationOnIcon/>
                                            </div>
                                            <div className="text pl-3">
                                                <p><span>Address:</span> Aljarf1, Ajman, UAE</p>
                                            </div>
                                        </div>
                                        <div className="dbox w-100 d-flex align-items-center">
                                            <div className="icon d-flex align-items-center justify-content-center">
                                                <LocalPhoneIcon></LocalPhoneIcon>
                                            </div>
                                            <div className="text pl-3">
                                                <p><span>Phone:</span> <a href="tel://1234567920">(+971) 55
                                                    8857447</a></p>
                                                <p><span>Phone:</span> <a href="tel://1234567920">(+971) 54
                                                    4949689</a></p>
                                            </div>
                                        </div>
                                        <div className="dbox w-100 d-flex align-items-center">
                                            <div className="icon d-flex align-items-center justify-content-center">
                                                <EmailRoundedIcon></EmailRoundedIcon>
                                            </div>
                                            <div className="text pl-3">
                                                <p><span>Email:</span> <a
                                                    href="mailto:info@intrados.ae">info@intrados.ae</a></p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <MyFooter></MyFooter>
    </div>);
};

export default Contact;
