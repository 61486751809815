import React from 'react';
import './home.css';
import MyFooter from '../../components/Footer/myfooter';
import Grid from '@mui/material/Grid';

const Home = () => {
    return (<>

        <box sx={{flexGrow: 1}} className="grid-header">
            <Grid container spacing={{xs: 5, md: 8, xl: 0}}>
                <Grid item xs={12} sm={12} md={12}>
                    <div className="angle">
                        <img src={require('../../images/header.jpg')} alt="working-engine"
                             style={{height: '500px', width: '1920px'}}/>
                        <p className="overlayText">Repair and maintenance of ships services
                            <br/>
                            <font className="overlayText2"> Since 2004, we've been offering complete
                                ship repair and maintenance solutions in all ports of the United Arab Emirates.</font>
                        </p>
                    </div>
                </Grid>
            </Grid>
        </box>

        <box sx={{flexGrow: 1}} className="home-grid1">
            <div className="home-box">
                <Grid container spacing={{xs: 1, md: 8, xl: 0}}>
                    <Grid item xs={12} sm={12} md={12}>
                        <div style={{textAlign: 'center', paddingBottom: '2%'}}><img className="main-image"
                                                                                     src={require('../../images/Intrados-logo.png')}
                                                                                     alt="ship"/>
                            <h3 className="home-logoP">Intrados</h3>
                        </div>
                        <div className="home-p1">
                            <p style={{textAlign: 'center'}}> Is an ISO certified company, dedicated to providing
                                innovative and
                                cost-effective
                                solutions to our customers to repair & maintain ships.
                                We are providing a wide range of services. from maintenance of all engines and
                                overhauls,
                                pump, turbo
                                charger, cooler, general turning services, crankshaft grinding on-site, bearing
                                remetalling
                                for White Metal, and other related services and products.
                            </p>
                            <p><u><a href="./services">View our services</a></u>
                            </p>
                        </div>
                    </Grid>
                </Grid>
            </div>
        </box>

        {/* Start of Container 2*/}

        <box sx={{flexGrow: 1}} className="grid2">
            <Grid container spacing={{xs: 5, md: 8, xl: 0}}>
                <Grid item xs={12} sm={12} md={7}>
                    <h1 className="home-h2">Experienced Professionals</h1>
                    <hr style={{
                        width: '50%',
                        paddingBottom: '1px',
                        color: 'gray',
                        backgroundColor: '#3CAFE1',
                        marginLeft: '0',
                        marginTop: '8px'
                    }}/>
                    <div className="home-p2">
                        <p>
                            We are constantly looking for ways to improve the efficiency and effectiveness of
                            our
                            quality management system. In today's highly competitive world, there is no margin
                            for
                            error. We must meet the needs of our customers while also looking for innovative
                            methods
                            to exceed their expectations on a consistent basis. Quality has become ingrained in
                            our
                            way of life.
                        </p>
                        <p>
                            Our goal is to meet all of the ship repair needs of our customers and to complete
                            the task on schedule. Our response time for emergency breakdown repairs is the
                            shortest in UAE, and as a result, we have received repeat business from existing
                            clients as well as new business from new customers.
                        </p>
                        <p><u><a href="/about/">Learn More About Us</a></u></p>
                    </div>
                </Grid>
                <Grid item xs={12} sm={12} md={5}>
                    <img src={require('../../images/dock-ship.jpg')} alt="working-engine"
                         style={{maxHeight: '500px', maxWidth: '500px', width: '100%', height: '100%'}} className="imageSize"/>
                </Grid>
            </Grid>
        </box>

        {/* Start of Container 3*/}

        <box sx={{flexGrow: 1}} className="grid3">
            <Grid container spacing={{xs: 5, md: 8, xl: 0}}>
                <Grid item xs={12} sm={12} md={7}>
                    <h1 className="home-h3">Our Values</h1>
                    <hr style={{
                        width: '50%',
                        paddingBottom: '1px',
                        color: 'gray',
                        backgroundColor: '#3CAFE1',
                        marginLeft: '0',
                        marginTop: '8px'
                    }}/>
                    <div className="home-p2">
                        <p> We are constantly striving to obtain the highest levels of accreditation for both
                            personnel and processes, and we set the bar extremely high in terms of safety and
                            quality in all parts of our operations. With a track record of returning customers, we
                            have demonstrated that quality and service are at the heart of the organization's
                            operations. We go above and beyond compliance by fostering an environment of
                            excellence throughout the organization. Putting a strong emphasis on continual
                            development has contributed to our excellent safety record.
                        </p>
                        <p> Everyone in our team takes pride in contributing to our efforts to establish and
                            maintain trust with our stakeholders, resulting in high customer satisfaction and
                            long-term business relationships with our customers.
                        </p>
                        <p><u><a href="./services">View our services</a></u></p>
                    </div>
                </Grid>
                <Grid item xs={12} sm={12} md={5}>
                    <img src={require('../../images/Webp.net-resizeimage.png')} alt="working-engine"
                         style={{maxHeight: '500px', maxWidth: '500px', width: '100%', height: '100%'}} className="imageSize"/>
                </Grid>
            </Grid>
        </box>

        {/* Start of Container 4*/}


        <MyFooter></MyFooter>
    </>);
};

export default Home;


