import React from 'react';
import './App.css';
import Navbar from './components/Navbar/Navbar';
import {BrowserRouter as Router, Routes, Route, Switch} from 'react-router-dom';
import Home from './pages/home/home';
import About from './pages/about/about';
import Services from './pages/service/services';
import Contact from './pages/contact/contact';

function App() {
    return (
            <Router>
                <Navbar/>
                <Routes>
                    <Route path="/" element={<Home/>}/>
                        <Route path="/home" element={<Home/>}/>
                        <Route path="/about" element={<About/>}/>
                        <Route path="/services" element={<Services/>}/>
                        <Route path="/contact" element={<Contact/>}/>
                </Routes>
            </Router>
    );
}

export default App;