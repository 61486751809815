import React from 'react';
import './myfooter.css';
import Grid from '@mui/material/Grid';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import Avatar from '@mui/material/Avatar';
import {deepPurple} from '@mui/material/colors';
import EmailRoundedIcon from '@mui/icons-material/EmailRounded';
import Link from '@mui/material/Link';
import InstagramIcon from '@mui/icons-material/Instagram';

const MyFooter = () => {
    return (<>
            <div className="hr-line"></div>


            <box sx={{flexGrow: 1}} className="footer-grid">
                <Grid container spacing={{xs: 1, md: 3, xl: 0}}>

                    <Grid item xs={6}>
                        <h3 >Contact</h3>
                    </Grid>


                    <Grid item xs={6}>
                        <h3 className="show-h1">Follow Us</h3>
                    </Grid>


                    <Grid item xs={12} sm={6} md={6}>
                        <div className="dbox w-100 d-flex align-items-center">
                            <div className="icon d-flex align-items-center justify-content-center">
                                <Avatar sx={{bgcolor: deepPurple[500]}}> <LocationOnIcon/>
                                </Avatar>
                            </div>
                            <div className="text-pl">
                                <p>Aljarf1, Ajman, UAE</p>
                            </div>
                        </div>
                    </Grid>


                    <Grid item xs={6} className="show-footer">
                        <Link href="https://www.instagram.com/intrados_ships_maintenancs/">
                            <InstagramIcon></InstagramIcon>
                        </Link>
                    </Grid>

                    <Grid item xs={12}>
                        <div className="dbox w-100 d-flex align-items-center">
                            <div className="icon d-flex align-items-center justify-content-center">
                                <Avatar sx={{bgcolor: deepPurple[500]}}> <LocalPhoneIcon/>
                                </Avatar>
                            </div>
                            <div className="text-pl2">
                                <p><span>Phone:</span> <a href="tel://+971558857447"></a>(+971) 55 8857447</p>
                                <p><span>Phone:</span> <a href="tel://+971558857447"></a>(+971) 54 4949689</p>
                            </div>
                        </div>

                    </Grid>


                    <Grid item xs={12}>
                        <div className="dbox w-100 d-flex align-items-center">
                            <div className="icon d-flex align-items-center justify-content-center">
                                <Avatar sx={{bgcolor: deepPurple[500]}}> <EmailRoundedIcon/>
                                </Avatar>
                            </div>
                            <div className="text-pl3">
                                <p><a href="mailto:info@intrados.ae"></a>info@intrados.ae</p>
                            </div>
                        </div>
                    </Grid>

                    <Grid item xs={6} className="bottom-hide">
                        <h3 className="bottom-hide">Follow Us</h3>
                    </Grid>

                    <Grid item xs={6} className="bottom-hide">
                        <Link href="https://www.instagram.com/intrados_ships_maintenancs/">
                            <InstagramIcon></InstagramIcon>
                        </Link>
                    </Grid>

                </Grid>
            </box>


            <div className="hr-line"></div>

            <footer className="footer">

                <p className="footer-p1" href="mailto:info@intrados.ae">info@intrados.ae <br/>
                    <a className="footer-p1">©{new Date().getFullYear()} Intrados</a></p>
            </footer>
        </>

    );
};

export default MyFooter;
