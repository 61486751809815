import React, {useEffect, useState} from 'react';
import {
    Nav, NavLink, Bars, NavMenu,
} from './NavbarElements';
import Sidebar from "../SideBar";
import {useLocation} from "react-router-dom";


const Navbar = () => {

    const ScrollToTop = (props) => {
        const location = useLocation();
        useEffect(() => {
            window.scrollTo(0, 0);
        }, [location]);
    }

    const [isOpen, setIsOpen] = useState(false)

    const toggle = () => {
        setIsOpen(!isOpen);
    }

    if (isOpen) {
        return (<Sidebar isOpen={isOpen} toggle={toggle}/>);
    }

    return (<>
        <Nav>
            <NavLink to='/'>
                <img src={require('../../images/Intra.png')} alt='logo' width={80} height={80}/>
            </NavLink>
            <Bars onClick={toggle}/>
            <NavMenu>
                <NavLink to='/home' activeStyle useEffect>
                    HOME
                </NavLink>
                <ScrollToTop></ScrollToTop>
                <NavLink to='/services' activeStyle>
                    SERVICES
                </NavLink>
                <ScrollToTop></ScrollToTop>
                <NavLink to='/about' activeStyle>
                    ABOUT US
                </NavLink>
                <ScrollToTop></ScrollToTop>
                <NavLink to='/contact' activeStyle>
                    CONTACT US
                </NavLink>
                <ScrollToTop></ScrollToTop>
            </NavMenu>
        </Nav>
    </>);
};

export default Navbar;

