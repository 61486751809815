import {FaBars} from 'react-icons/fa';
import {NavLink as Link} from 'react-router-dom';
import styled from 'styled-components';

export const Nav = styled.nav`
  background-image: -webkit-linear-gradient(330deg, #225479 16%, #89f7fe 100%);
  background-image: -o-linear-gradient(330deg, #225479 16%, #89f7fe 100%);
  background-image: linear-gradient(120deg, #225479 16%, #89f7fe 100%);
  height: 110px;
  display: flex;
  justify-content: space-between;
  //padding: 0.5rem calc((100vw - 1000px) / 2);
  padding: 0.5rem calc((70vw - 1000px) / 2);
  z-index: 10;
  box-shadow: 0 10px 10px 0 rgba(0, 0, 0, 0.16);
  margin-top: 0.1px;
  overflow: hidden;
  position: sticky;
  top: 0;
  @media screen and (max-width: 768px) {
    background-image: -webkit-linear-gradient(330deg, #225479 50%, #89f7fe 100%);
    background-image: -o-linear-gradient(330deg, #225479 50%, #89f7fe 100%);
    background-image: linear-gradient(120deg, #225479 50%, #89f7fe 100%);
  }


`;

export const NavLink = styled(Link)`
  color: #fff;
  border-bottom: aquamarine;
  font-family: "Arabic";
  //flex: 1;
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0 1.5rem;
  height: 100%;
  cursor: pointer;

  &:hover {
    color: darkgrey;
  }

  &.active {
    color: #FFE5B4;
  }
`;

export const Bars = styled(FaBars)`
  display: none;
  color: #fff;
  @media screen and (max-width: 768px) {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 75%);
    font-size: 1.8rem;
    cursor: pointer;
  }
`;

export const NavMenu = styled.div`
  display: flex;
  align-items: center;
  margin-right: -24px;
  font-size: large;
  font-weight: bold;
  padding: 50px;

  @media screen and (max-width: 768px) {
    display: none;
  }
`;